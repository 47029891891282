import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Input,
  InputLabel,
  Typography,
  Box,
} from '@mui/material';


const CreateProjectDialog = ({ isOpen, onClose, onCreate }) => {
  const [projectName, setProjectName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };


  const handleCreateProject = async () => {
    try {
      const formData = new FormData();
      formData.append('project_name', projectName); 
      formData.append('thumbnail_image_file', selectedFile);     
      const response = await fetch('https://dreambox-api.netlify.app/api/createProject', {
        method: 'POST',
        body: formData,
      });
  
      const responseData = await response.json();
      onClose();
      setProjectName('');
      setSelectedFile(null);
  
      const updatedProjectsResponse = await fetch('https://dreambox-api.netlify.app/api/fetchProjects');
      const updatedProjectsData = await updatedProjectsResponse.json();
  
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };
  
 
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Project</DialogTitle>
      <DialogContent>
        <TextField
          sx={{
            '&:hover': {
              borderColor: '#b89043',
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              borderColor: '#b89043',
            },
          }}
          autoFocus
          margin="dense"
          label="Project Name"
          fullWidth
          value={projectName}
          onChange={handleProjectNameChange}
        />
        <Box mt={2}>
          <InputLabel>Upload Thumbnail</InputLabel>
          <Input type="file" onChange={handleFileChange} />
          {selectedFile && (
            <Typography variant="caption" mt={1}>
              Selected File: {selectedFile.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: '#b89043',
            color: 'White',
            textTransform: 'capitalize',
            '&:hover': { backgroundColor: '#b89043' },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateProject}
          sx={{
            backgroundColor: '#b89043',
            color: 'White',
            textTransform: 'capitalize',
            '&:hover': { backgroundColor: '#b89043' },
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectDialog;
