import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Drawer,
  List,
  AppBar,
  Toolbar,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import CreateProjectDialog from '../components/projectModal';
import logo from '../assets/dreamLogo.png';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 300;

const Dashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleOpenCreateDialog = () => {
    setIsCreateDialogOpen(true);
  };

  const handleImageClick = (projectId) => {

    navigate(`/listImages/${projectId}`, { state: { projectId: projectId } });

  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
  };

  const handleCreateProject = (projectName) => {
    console.log('Creating project:', projectName);
    handleCloseCreateDialog();
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await axios.delete('https://dreambox-api.netlify.app/api/deleteProject', {
        data: {
          project_id: projectId,
        },
      });
      const response = await axios.get('https://dreambox-api.netlify.app/api/fetchProjects');
      setProjects(response.data);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDrawerOpen(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    axios.get('https://dreambox-api.netlify.app/api/fetchProjects')
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#f6f5f1' }}>
        <Toolbar>
          <IconButton
            color="#f6f5f1"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2, display: isDrawerOpen ? 'none' : 'block' }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Box sx={{ position: 'absolute', top: 0, right: 0, marginRight: 2, marginTop: 10 }}>
            <Button variant="contained" sx={{
              backgroundColor: '#b89043',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#b89043',
              },
            }} onClick={handleOpenCreateDialog}>
              Create Project
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
          display: { xs: 'none', md: 'block' },
        }}
        open={isDrawerOpen}
      >
        <Toolbar />
        <List>
          <Typography variant="h6" sx={{ margin: '10px 0', color: '#333', fontWeight: 'bold', paddingLeft: 2 }}>
            Projects
          </Typography>
        </List>
      </Drawer>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingLeft: 0,
          '@media (min-width: 600px)': {
            paddingLeft: `${drawerWidth}px`,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            color: '#333',
            textAlign: 'center',
            padding: '20px',
            width: '100%',
          }}
        >
          {loading ? (
            <Typography variant="body1">Loading...</Typography>
          ) : error ? (
            <Typography variant="body1">Error fetching data</Typography>
          ) : projects.length > 0 ? (
            <Grid container spacing={4} justifyContent="flex-start" sx={{
              '@media (min-width: 600px)': {
                marginLeft: '-90px',
                marginTop: "30px" // Adjusted margin top here
              },
              '@media (max-width: 600px)': {
                marginTop: "40px" // Adjusted margin top for mobile view
              }
            }}>
              {projects.map((project, index) => (
                <Grid item key={project.project_id} xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      padding: '20px',
                      margin: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100% !important',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Box
                      onClick={() => handleImageClick(project.project_id)}
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '200px',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={project.thumbnail_url}
                        alt={project.project_name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: '20px', textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle2">{project.project_name}</Typography>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteProject(project.project_id)}
                        sx={{ marginTop: '-10px' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">No projects to show</Typography>
          )}
        </Box>
      </Container>
      <CreateProjectDialog
        isOpen={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        onCreate={handleCreateProject}
      />
    </Box>
  );
};

export default Dashboard
