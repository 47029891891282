import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useLocation } from 'react-router-dom';

const CreatImageDialog = ({ isOpen, onClose }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const location = useLocation();
  const { projectId } = location.state;
  const [loading, setLoading] = useState(false); // State to indicate loading state

  const handleImageChange = (e) => {
    const files = e.target.files;
    setSelectedImages(files);
  };

  const handleCreateImage = async () => {
    if (selectedImages.length > 0) {
      setLoading(true); // Set loading state to true

      try {
        const formData = new FormData();
        formData.append('project_id', projectId); 
        for (let i = 0; i < selectedImages.length; i++) {
          formData.append('image_files', selectedImages[i]);
        }
    
        const response = await fetch('https://dreambox-api.netlify.app/api/addImages', {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        console.log('Images added successfully:', data);

        // Fetch the updated data immediately after adding images
        const updatedResponse = await fetch(`https://dreambox-api.netlify.app/api/fetchImages?project_id=${projectId}`);
        const updatedData = await updatedResponse.json();
        console.log('Updated records:', updatedData);

        // Now set the updated data to state and close the dialog
        onClose();
      } catch (error) {
        console.error('Error adding images:', error);
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };
    
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Add Images</DialogTitle>
      <DialogContent>
        <input
          type="file"
          accept="image/*"
          multiple // Allow selection of multiple files
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="image-upload-input"
        />
        <label htmlFor="image-upload-input">
          <IconButton component="span" color="primary">
            <AddPhotoAlternateIcon />
          </IconButton>
          <Typography variant="body1" color="primary">
            Upload Images
          </Typography>
        </label>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{color:"#b89043"}}>
          Cancel
        </Button>
        <Button onClick={handleCreateImage} sx={{color:"#b89043"}}>
          {loading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatImageDialog;
