import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Drawer,
  List,
  AppBar,
  Toolbar,
  Button,
  Container,
  Typography,
  Box,
  IconButton,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import CreatImageDialog from '../components/imageModal';
import logo from '../assets/dreamLogo.png';
import { useLocation } from 'react-router-dom';
const drawerWidth = 300;

const ListImages = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { projectId } = location.state;
  

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  useEffect(() => {
    const handleResize = () => {
      setIsDrawerOpen(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    fetchData(); 

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://dreambox-api.netlify.app/api/fetchImages?project_id=${projectId}`);
      setImageData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setLoading(false);
    }
  };

  const handleDeleteImage = async (imageId) => {
    try {
      await axios.delete(`https://dreambox-api.netlify.app/api/deleteImage?image_id=${imageId}`);
      fetchData(); 
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleImageCreateDialog = () => {
    setIsImageDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setIsImageDialogOpen(false);
    
  };

  const handleImage = (projectName) => {
    console.log('Creating project:', projectName);
    handleCloseCreateDialog();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#f6f5f1' }}>
        <Toolbar>
          <IconButton
            color="#f6f5f1"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2, display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Box sx={{ position: 'absolute', top: 0, right: 0, marginRight: 2, marginTop: 10 }}>
            <Button variant="contained" sx={{
              backgroundColor: '#b89043',
              '&:hover': {
                backgroundColor: '#b89043',
              },
            }} onClick={handleImageCreateDialog}>
              Add Image
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
          display: { xs: 'none', md: 'block' },
        }}
        open={isDrawerOpen}
      >
        <Toolbar />
        <List>
          <Typography variant="h6" sx={{ margin: '10px 0', color: '#333', fontWeight: 'bold', paddingLeft: 2 }}>
            My Images
          </Typography>
        </List>
      </Drawer>
      <Container
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          paddingLeft: 0,
          '@media (min-width: 600px)': {
            paddingLeft: `${drawerWidth}px`,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            color: '#333',
            textAlign: 'center',
            padding: '20px',
            width: '100%',
            overflowX: 'auto', // Enable horizontal scrolling
          }}
        >
          { error ? (
            <Typography variant="body1">Error fetching data</Typography>
          ) : imageData.length > 0 ? (
            <Grid container spacing={4} alignItems="center" justifyContent="flex-start">
              {imageData.map((image) => (
                <Grid item key={image.image_id} xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={image.image_url}
                      alt={`Image ${image.image_id}`}
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => handleDeleteImage(image.image_id)}
                      sx={{ marginTop: '5px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </Box>
      </Container>
      <CreatImageDialog
        isOpen={isImageDialogOpen}
        onClose={handleCloseCreateDialog}
        onCreate={handleImage}
      />
    </Box>
  );
};

export default ListImages;
