// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import MyProject from "./pages/myProject";
import ListImages from "./pages/listImages";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MyProject />} />
          <Route path="/listImages/:projectId" element={<ListImages />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
